$PrimeryColor: #303030;
$SecondryColor: #5d6e84;
$greenColor: #46bfa5;
$redColor: #e01a4f;
$borderColor: #e7eaf0;
$boxShadow: 0px 2px 4px rgba(205, 205, 205, 0.2);

.surveySearchMain {
  margin-top: 10px;
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.surveySearch input {
  border: 1px solid $borderColor;
  border-radius: 4px;
  box-shadow: $boxShadow;
  padding: 9px 0px 8px 28px;
}

.surveySearch input::-webkit-input-placeholder {
  /* Edge */
  font-size: 13px !important;
}


.surveySearch input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 13px !important;
}

.surveySearch input::placeholder {
  font-size: 13px !important;
}

.searchIcon img {
  height: 17px;
}

.nfbold {
  font-weight: bold !important;
  color: $PrimeryColor !important;
}

.surveySearch {
  position: relative;
}

.searchIcon {
  position: absolute;
  left: 7px;
  top: 10px;
}

button.surveyBtn {
  background: $greenColor;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  border: none;
  padding: 11px 18px;
  display: block;
  border-radius: 50px;
  float: right;
}

.closeBtn {
  position: absolute;
  right: 4px;
  top: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  z-index: 99;

  :hover {
    opacity: 1;
  }
}

.surwayheading {
  display: flex;
  align-items: center;
}

.headingIcon {
  margin-right: 8px;
  float: left;
}

span.headingIcon img {
  width: 18px;
}

.surveysubHeading {
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  position: relative;
  top: 2px;
  color: #000;
}

a.active.nav-link .surveysubHeading {
  color: $greenColor;
}

img.we12 {
  width: 19px;
  height: 22px;
}

.cardSeaction {
  margin-top: 10px;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchboxRight {
  width: calc(100% - 155px);
}

.surveyCardbody {
  float: left;
  width: 100%;
}

.fullheading {
  font-size: 18px;
  font-weight: 700;
}

.customeCardSurvay {
  border-top: 5px solid$greenColor;
  border-radius: 4px;
}

.customeSpace {
  padding: 20px 15px !important;
}

.newIcons.dropdown {
  text-align: right;
}

.shadebox {
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  transition: 0.3s;
}

.surveyMainBody {
  width: 520px;
  outline: none;
  position: relative;
}

.setW-750 {
  width: 750px;
}

.setW-800 {
  width: 800px;
}

.surveyboxPoup {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: #fff;
  padding: 30px;
  border: 1px solid $borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: 0.7s;
}

.surveyContect {
  text-align: center;
}

.surveyContect h2 {
  font-size: 18px;
  color: $PrimeryColor;
  font-weight: 700;
}

.surveyContect p {
  color: $SecondryColor;
  font-size: 14px;
}

.surveyBox {
  box-shadow: $boxShadow;
  border-radius: 4px;
  text-align: center;
  border: 2px solid$borderColor;
  padding: 20px;
  width: 224px;
}

.surveyBox h2 {
  font-size: 16px;
  color: $PrimeryColor;
  font-weight: bold;
}

.surveyBox h4 {
  font-size: 13px;
  color: $PrimeryColor;
  font-weight: bold;
}


.surveyBox p {
  color: $SecondryColor;
  font-size: 14px;
  margin: 0px;
}

.surwaybody {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}

.seletedColor,
.surveyBox:hover,
.surveyOtherbox:hover {
  border-color: $greenColor !important;
}

.surveyGreenBtnLg {
  color: $greenColor;
  border: 1px solid$greenColor !important;
  border-radius: 25px;
  width: 100%;
  display: block;
  font-size: 14px;
  padding: 8px;
  background-color: #fff;
  transition: 0.3s;
}

.surveyGreenBtnLg:disabled {
  opacity: 0.4;
}

.surveyGreenBtnLg:hover {
  color: #fff;
  background-color: $greenColor;
}

.surveyButton {
  margin-top: 30px;
}

.shadebox.show {
  display: block;
}

.surveyboxPoup.show {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.trasparentOverflow {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
}

.eventShow {
  max-width: 484px;
  width: 100%;
}

.surveyboxImg {
  width: 60px;
  height: 60px;
  background: #46bfa5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  text-align: center;
  margin: 0 auto 15px auto;
}

.surveyboxImg img {
  width: 25px;
}

.userSec {
  width: 560px !important;
}

textarea.surveyName {
  width: 100%;
  padding: 10px 16px;
  height: 70px;
  border: 1px solid $borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
}

textarea.surveyDiscription {
  width: 100%;
  padding: 10px 16px;
  height: 150px;
  border: 1px solid $borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
}

textarea.surveyDiscription.setnewpaddingcustomesurvey {
  padding: 5px 8px !important;
}

.surveyOtherbox {
  width: 100%;
  max-width: 160px;
  padding: 25px 10px 20px;
  border: 2px solid $borderColor;
  border-radius: 3px;
  display: flex;
  flex-flow: column;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  text-align: center;
}

.surveyDorpList.inerPoupDrop.show {
  width: 168px;
}

.deleteMain {
  min-width: 500px;
  width: 100%;
}

.surveyCircle {
  background: $greenColor;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.surveyHeading.headingSwitchTop.formanager {
  margin-top: 16px;
}

.flexRownew {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0px;
}

.flexRownew.fblock {
  display: block;
  height: 0;
  width: 15px;
  float: left;
}

.surveyHeading {
  width: 150px;
  margin: 50px 0 0 0;
}

.continueBtn:disabled {
  opacity: 0.4;
}

.darkGreen {
  color: $greenColor;
}

.borderMain,
.question {
  padding: 15px;
  background: #ffffff;
  border: 1px solid $borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
}

.surveyPopHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}

.lightColor {
  color: #888;
}

.leftArea {
  width: 100%;
  max-width: 770px;
}

.question {
  padding: 10px;
  outline: none;
}

.rightArea {
  width: 100%;
  max-width: 167px;
}

.answer {
  width: 100%;
  list-style: none;
}

.answer li {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.selectbox,
.answertype {
  padding: 7px;
  border: 1px solid $borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  outline: none;
}

.roundCircle {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 100%;
  margin-right: 10px;
}

.w30 {
  width: 530px;
}

.underline:hover {
  text-decoration: underline;
}

.d-column {
  flex-flow: column;
}

.arrowIcon {
  padding: 7px;
  height: 25px;
}

.numberI {
  height: 30px;
  align-items: center;
}

.deleteIcon {
  width: 43px;
  height: 36px;
  align-items: center;
}

.themshadow .overlist {
  border: none;
  width: 40px;
  padding: 3px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  color: $SecondryColor !important;
  outline: none;
}

.overlist.themshadow:focus-visible {
  outline: none;
}

.overlist.themshadow:hover {
  border-color: #000;
}

.myLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;

  img {
    position: absolute;
    top: 20%;
    width: 70px;
    height: 70px;
  }
}

.crossOption:hover {
  opacity: 0.6;
}


.editpoupheading {
  text-align: center;
  position: relative;
  top: -10px;
  max-width: 500px;
}

.editpoupheading h2 {
  font-size: 16px;
}

.surveyPopHeader img {
  width: 35px;
}

.formSurvey label {
  display: block;
}

.formSurvey input {
  display: block;
  width: 100%;
  height: 48px;
  border: 1px solid$borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
}

.formSurvey {
  margin-top: 20px;
}

.buttonSurvey {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  flex-direction: column;
  float: left;
  width: 100%;
}

button.submitSurvey {
  background: $greenColor;
  color: #fff;
  border: none;
  padding: 10px 25px;
  border-radius: 50px;
  font-size: 14px;
  margin-right: 5px;
}

.submitSurvey:disabled {
  opacity: 0.6 !important;
}

.cancelSurvey {
  background: #ddd;
  color: #000;
  border: none;
  padding: 10px 25px;
  border-radius: 50px;
  font-size: 14px;
  margin-left: 5px;
}

.recipientsPopup {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 600px;
  background: #fff;
  padding: 50px;
  border: 1px solid $borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
  transition: 0.7s;
}

.surveyboxPoup.nopadding.show {
  padding: 50px 0px;
}

.padderIner {
  padding: 0px 15px;
}

.addRecipientsForm-header h2 {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: $PrimeryColor;
}

.newHeaderlogo {
  top: 9px;
  margin-bottom: 33px;
}

.recipientSearch {
  position: relative;
  margin: 24px 0px;
}

.surveySearch.marTop {
  margin: 15px 0px;
}

.headerHeadingPop {
  position: relative;
  margin-top: -20px;
}

.spinner_main {
  position: absolute;
  top: 98px;
  // left: 48px; //for dev only
}


button.saveBtn.mt-1.btn-save.buildSurevey {
  padding: 11px 23px 11px 23px;
  font-size: 13px;
  position: relative;
  top: -4px;
}

.surveyPara {
  color: #5d6e84;
  font-size: 14px;
  text-align: center;
  margin: 0px;
}

/*--customeCard---*/
.surveyCard {
  background: #ffffff;
  box-shadow: $boxShadow;
  border-radius: 4px;
  border-top: 5px solid$greenColor;
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  padding: 20px 16px;
  position: relative;
}

.surveyCard:hover .surveyCardHeading {
  color: $greenColor;
}

.align-name {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.surveyCardLeft {
  float: left;
}

.surveyCardRight {
  margin-left: 7px;
  position: relative;
}

.surveyCardRight.nomarginleft {
  margin-left: 0;
  width: 100%;
}

.surveyCardHeading {
  font-size: 14px;
  font-weight: 700;
  color: $PrimeryColor;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  width: calc(100% - 80px);
  overflow: hidden;
}

.surveySubHeading {
  margin-top: 10px;
}

.surveySubHeading p {
  font-size: 14px;
  color: #596a80;
  margin: 0;
  padding: 0;
}

.surveySubHeading span {
  color: $PrimeryColor;
}

.surveyCardDropdown {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dotsIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 18px;
}

.surveyCardDropdown:hover .dot {
  background-color: $greenColor;
}

span.second.dot {
  margin: 0px 3px;
}

.mainsurveyDrop {
  position: relative;
}

.greenCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.dot {
  width: 4px;
  height: 4px;
  display: block;
  background: #596a80;
  border-radius: 50px;
}

.verticalDots {
  transform: rotate(90deg) !important;
}

.surveyDorpList {
  position: absolute;
  top: 40px;
  right: 0;
  width: 160px;
  display: block;
  border: 1px solid $borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  z-index: 2;
}

.setmarginLeft {
  margin-left: -7px;
}

h2.surveyCardHeading.newtabletext.setforname {
  width: 400px;
}

.setmarginLeft {
  margin-left: -7px;
}

h2.surveyCardHeading.newtabletext.setforname {
  width: 100%;
}

.tableHeadCol.setmarginLeft.atlast-5 {
  margin-left: 5px;
}

.setformhalf {
  width: 85%;
}

.lfpading0 {
  padding: 0px 0px;
  margin-top: 1px;
}

.danger-color {
  color: $redColor;
}

.surveyDorpList.inerPoupDrop.show {
  width: 175px;
  top: 35px;
  visibility: visible;
  opacity: 1;
}

.recipientlistPannel:last-child .surveyDorpList.inerPoupDrop.show {
  top: -38px;
}

.recipientlistPannel:first-child .surveyDorpList.inerPoupDrop.show {
  top: 38px;
}

.surveyDorpList.inerPoupDrop {
  top: 60px;
}

.surveyDorpList.inerPoupDrop.show>ul>li {
  cursor: pointer;
  color: $redColor;
}

.surveyDorpList.inerPoupDrop.show>ul>li:hover {
  opacity: 1;
}

.surveyDorpList.show {
  top: 30px;
  visibility: visible;
  opacity: 1;
}

.flexb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.w50 {
  width: 50%;
  padding: 0px 5px;
}

.surveyDorpList>ul {
  margin: 0;
  padding: 0;
}

.surveyDorpList>ul>li {
  list-style: none;
  border-bottom: 1px solid #ebedf3;
  display: block;
  transition: 0.3s;
  float: left;
  width: 100%;
}

.mt-2p {
  margin-top: 2px !important;
}

.surveyDorpList>ul>li:last-child {
  border-bottom: none;
}

.surveyDorpList>ul>li a,
.linkCl {
  float: left;
  width: 100%;
  padding: 13px 12px;
  transition: 0.5s;
}

.linkCl {
  color: #000000;
}

.surveyDorpList>ul>li a:hover,
.linkCl:hover {
  opacity: 0.5;
  color: $PrimeryColor;
}

.listIcon {
  float: left;
}

.listText {
  margin-left: 20px;
}

.listText {
  margin-left: 8px;
  padding: 0;
  margin-bottom: 0px;
  display: inline-block;
}

.surveyPopHeading {
  text-align: center;
}

.surveyPopHeading h2 {
  font-weight: 700;
  font-size: 20px;
  color: $PrimeryColor;
}

.surveyPopHeading p {
  color: $SecondryColor;
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 20px 0;
}

.surwayDeltePoupBtn {
  border: 1px solid$redColor;
  background-color: #fff;
  border-radius: 24px;
  display: block;
  width: 100%;
  color: $redColor;
  padding: 12px 0px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  transition: 0.3s;
}

.surwayDeltePoupBtn:hover {
  background-color: $redColor;
  color: #fff;
}

button.surwayCancelPoupBtn {
  border: 1px solid$greenColor;
  border-radius: 24px;
  display: block;
  width: 100%;
  color: $greenColor;
  padding: 12px 0px;
  font-size: 16px;
  font-weight: 600;
  background: #fff;
  transition: 0.3s;
}

button.surwayCancelPoupBtn:hover {
  background-color: $greenColor;
  color: #fff;
}

.marBottom {
  margin-bottom: 16px;
}

.recipientsTable {
  width: 100%;
}

.recipientTablehrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  border-top: 1px solid rgba(230, 230, 230, 0.8);
}

.recipientTablehrow:first-child {
  border-top: none;
}

.border-bottom-top {
  border-top: 1px solid rgba(230, 230, 230, 0.8) !important;
}

.border-rowbottom:nth-child(3n + 1) {
  background-color: #f5f5f5;
}

.borderall {
  border: 1px solid$borderColor !important;
}

.border-rowbottom {
  border-bottom: 1px solid rgba(230, 230, 230, 0.8);
}

.setforscroll {
  overflow: auto;
  min-height: 250px;
  max-height: 250px;
}

.recipientPanneltypeCard {
  margin-top: 20px;
}

.w-100.mt-2.matterpannel {
  height: auto;
  overflow: auto;
}

.recipientTableRcol {
  width: 100%;
}

.recipientsTable p.tableContent.sanaton.mestVet.manageUserleftSpace {
  margin: 0px;
  padding: 0px;
}

.recipientsTable p.recipientsSelectText {
  margin: 0px 0px 0px 10px;
  padding: 0px;
}

.recipientShorting {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.reciGreen {
  color: $greenColor;
}

.rightTextrec {
  padding: 0px 0px 0px 8px;
  font-size: 14px;
  color: $SecondryColor;
  font-weight: 400;
}

span.sorIcons {
  margin-left: 5px;
}

.figureImg {
  float: left;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
}

.nospacepara {
  cursor: pointer;
}

.figureImg.square {
  border-radius: 0px;
  width: 25px;
  height: 25px;
}

.figureImg.square+.figureText {
  margin-left: 34px;
  text-transform: uppercase;
  margin-top: -1px;
}

.figureText {
  margin-left: 43px;
}

.pointercurser {
  cursor: pointer;
}

.recipientTablehrow.settedborderbottom.pointercurser:hover {
  opacity: 0.7;
}

// .surveyboxPoup.nopadding.recipentpop.show {
//     padding: 40px 0px 20px;
// }

.borderBottom {
  border-bottom: 1px solid $borderColor;
  background-color: #fff;
}

.setPaddingPop {
  padding: 36px 0px 0px !important;
}

span.checkmark.toptable {
  top: 10px;
}

.figuremain {
  margin-left: 10px;
}

.figureText p {
  font-size: 12px;
  color: #5d6e84;
  font-weight: 400;
  margin: 0;
  padding: 0px;
}

.figureText h2 {
  font-size: 13px;
  font-weight: 700;
  color: #303030;
  margin: 0px;
  line-height: 18px;
}

.rightTablerec {
  float: left;
  width: 50%;
}

.flextTable {
  width: 100%;
}

.recipientsTable .checkmark {
  height: 20px;
  width: 20px;
  background-color: #d1d1d1;
}

.recipientsTable .chk .checkmark:after {
  left: 7px;
  top: 5px;
}

.rightTableText {
  float: left;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 33px;
  font-weight: 400;
  font-size: 12px;
  color: $SecondryColor;
}

.recipientDropdown {
  position: absolute;
  top: 60px;
  background: #fff;
  box-shadow: $boxShadow;
  z-index: 2;
  border: 1px solid $borderColor;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  width: 200px;
}

.noDataFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 210px;
  font-size: 17px;
}

.recipientDropdown.show {
  visibility: visible;
  opacity: 1;
  top: 20px;
  top: 30px;
}

.recipientDropdownMain>ul {
  margin: 0px;
  padding: 0;
}

.recipientDropdownMain>ul>li {
  border-bottom: 1px solid#EBEDF3;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 400;
  color: $PrimeryColor;
  list-style: none;
  cursor: pointer;
  text-align: left;
}

.filterchecks {
  box-shadow: none !important;
  float: right;
  cursor: pointer;
  display: none;
}

.pfcheck {
  top: 0px;
  left: 3px;
}

.whitenone {
  display: none;
}

.surwayborder:hover .iconBtn {
  display: none;
}

.surwayborder:hover .whitenone {
  display: block;
}

.surwayborder:hover {
  background-color: $greenColor;
  color: #fff;
}

.filterchecks.show {
  display: block;
}

.recipientDropdownMain>ul>li:hover {
  background-color: #f2f2f2;
}

.dropDownContainer {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.recipientlistPannel-figure {
  float: left;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50px;
}

.recipientlistPannel-boxPannel {
  margin-left: 45px;
  display: flex;
  align-items: center;
  position: relative;
}

.rightDots {
  position: absolute;
  top: 0;
  right: 0;
}

.rightDots .dotsIcons {
  padding: 16px 4px;
}

.recipientBox h2 {
  font-size: 14px;
  font-weight: bold;
  color: $PrimeryColor;
  margin: 0;
}

.recipientBox p {
  font-size: 12px;
  font-weight: 400;
  color: $SecondryColor;
}

.recipientlistPannel {
  float: left;
  width: 100%;
}

.alllistPannel {
  height: calc(70% - 10%);
  margin-bottom: 19px;
}

.vhheight {
  height: calc(70vh - 30%);
}

.boxScrollpannel {
  overflow: auto;
  padding: 0px 7px 0px 0px;
  height: 100%;
  max-height: 370px;
}

.disableLevel {
  opacity: 0.3;
  pointer-events: none;
}

.allRecipients {
  color: $greenColor;
  font-size: 14px;
  font-weight: bold;
}

span.editableText {
  font-size: 12px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 4px;
  background: #f1f2f4;
  margin-left: 8px;
  color: $SecondryColor;
}

.recipientPanneltypeCard {
  margin-top: 35px;
}

.recipientPanneltypeCard h3 {
  font-size: 18px;
  font-weight: bold;
  color: $PrimeryColor;
  margin: 0 0 5px 0;
}

.themshadow {
  border: 1px solid $borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
}

select.overlist.themshadow {
  padding: 5px 2px;
  position: relative;
  color: $SecondryColor;
  cursor: pointer;
  width: 50px;
  text-align: center;
}

.recipientPanneltypeCard p {
  font-size: 14px;
  font-weight: 600;
  color: #303030;
  margin: 0px;
}

.askPannelright {
  padding-right: 10px;
}

.askPannelleft {
  padding-left: 10px;
  height: 80vh;
  background-color: transparent !important;
}

.leftqaBox {
  float: left;
  width: calc(100% - 50px);
}

.surwayborder {
  border: 1px solid$greenColor;
  color: $greenColor;
  background-color: #fff;
}

.surwayborder {
  border: 1px solid #46bfa5;
  color: #46bfa5;
  background-color: #fff;
  display: flex;
}

.surwayborder {
  border: 1px solid #46bfa5;
  color: #46bfa5;
  background-color: #fff;
  border-radius: 50px;
}

.errorCl {
  border-left: 4px solid#e00;
}

.errorCl .qautionIcons {
  background-color: #e00;
}

.surwayborder.btn-small {
  font-size: 12px;
  font-weight: 400;
  padding: 8px 12px;
  width: 136px;
}

.notpointer {
  pointer-events: none;
}

.cir {
  width: 7px;
  height: 7px;
  display: inline-block;
  border-radius: 50px;
  position: relative;
  top: -1px;
  margin-left: 2px;
}

.warring {
  background: #ffa705;
}

.setheightcustome {
  height: 40px;
}

.success {
  background-color: #28a745;
}

.btn-relative {
  position: relative;
}

.iconBtn {
  margin-right: 5px;
}

.searchboxleft {
  display: flex;
  width: 230px;
  justify-content: inherit;
}

.mr-16 {
  margin-right: 16px;
}

.midlePointSurvey-heading h1 {
  font-size: 18px;
  font-weight: bold;
  color: #303030;
  margin: 0px;
  padding-bottom: 2px;
  overflow: hidden;
}

.pad10 {
  padding: 10px !important;
}

.midlePointSurvey-heading p {
  font-size: 12px;
  font-weight: 300;
  color: #5d6e84;
  margin: 0;
}

.autowidthst {
  width: auto !important;
}

.autowidthstautonest {
  width: 106px !important;
}

.respondLeft img {
  width: 40px;
}

.respondTable {
  background: #ffffff;
  border: 1px solid #e7eaf0;
  box-shadow: $boxShadow;
  border-radius: 4px;
  padding: 20px;
}

.respondLeft {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $greenColor;
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

.respondText {
  margin-left: 50px;
}

.respondText p {
  margin: 0;
  font-size: 13px;
  color: $SecondryColor;
}

.respondText h3 {
  font-size: 16px;
  font-weight: bold;
  color: $PrimeryColor;
  margin: 0;
}

.restableBorder {
  background: #ffffff;
  border: $borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
  padding: 20px;
  float: left;
}

.ft-14 {
  font-size: 14px;
  font-weight: 400;
  color: $PrimeryColor;
}

.fheadt-14 {
  font-size: 14px;
  font-weight: bold;
  color: $SecondryColor;
}

/*---newtable--*/
h2.surveyCardHeading.newtabletext {
  font-size: 15px;
  font-weight: 600;
  padding-top: 0;
}

.text-sucess:hover {
  opacity: 0.7;
  border-bottom: none;
}

.backbtn.reltepoup {
  margin-top: -21px;
  position: relative;
  left: -15px;
}

.padderIner {
  padding: 0px 15px 15px;
}

.resCol.col-100.dolerwick {
  width: 15% !important;
}

.resTableRow {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $borderColor !important;
}

.tableHeadCol p {
  margin: 0;
  border: none;
}

.resCol {
  padding: 8px 0px;
}

.iconBtn img {
  width: 13px;
}

.col-350 {
  width: 55%;
}

.col-250 {
  width: 40%;
}

.col-230 {
  width: 35%;
}

.col-234 {
  width: 64%;
}

.col-120 {
  width: 20%;
}

.positionrelate {
  position: relative;
}

.col-100 {
  width: 15%;
}

.col-200 {
  width: 25%;
}

.relativeCol {
  position: relative;
}

.listfull {
  float: left;
  width: 100%;
}

.buttonSurvey.fullBox {
  float: left;
  width: 100%;
}

p.recipientsSelectText.fontforcheck {
  font-size: 14px;
  font-weight: 600;
}

.formMessage {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.formMessage label {
  font-size: 14px;
  font-weight: 400;
  color: $SecondryColor;
}

.formMessage textarea {
  border: 1px solid $borderColor;
  border-radius: 4px;
  padding: 8px;
  outline: none;
}

.innertx {
  font-size: 13px;
}

.dorpMainB {
  padding: 10px 10px 10px 15px;
}

.dorpMainB:hover,
.hovertype:hover {
  opacity: 0.5;
}

span.qautionIcons {
  float: left;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #46bfa5;
  color: #fff;
  border-radius: 50px;
  font-size: 12px;
}

.limitType {
  position: relative;
}

span.resri {
  font-weight: bold;
  color: #5d6e84;
  font-size: 14px;
}

.limitType {
  position: relative;
}

.qlist h2 {
  margin-left: 40px;
  font-size: 14px;
  padding-top: 6px;
  color: $PrimeryColor;
  font-weight: bold;
  text-transform: initial;
}

.iconBtn.nextPage.hover {
  display: none;
}

span.iconBtn.nextPage {
  margin-right: 3px;
}

.innertx:hover .iconBtn.nextPage.hover {
  display: block;
  float: left;
}

.innertx:hover .iconBtn.nextPage {
  display: none;
}

label.quationpannelbox {
  position: relative;
  padding: 11px 13px 10px;
  cursor: pointer;
  //text-transform: capitalize;
}

label.quationpannelbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.pb-10 {
  padding: 0px 0px 15px;
}

.restableTableBody .resTableRow:last-child {
  border-bottom: none;
}

.borderpadding {
  padding: 17px 20px 3px 20px;
}

.qansPannel {
  margin-top: 20px;
  margin-left: 40px;
  position: relative;
}

p.charatorleft {
  position: absolute;
  bottom: -8px;
  right: 10px;
  font-size: 12px;
  color: $SecondryColor;
  font-weight: bold;
}

.qansPannel textarea {
  border: 1px solid$borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
  height: 120px;
  outline: none;
  padding: 10px;
  width: 100%;
  font-size: 15px;
}

.markquation {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid$borderColor;
  box-shadow: $boxShadow;
  border-radius: 4px;
}

.quationpannelbox input:checked~.markquation {
  background-color: $greenColor;
  border: 1px solid $greenColor;
}

.quationpannelbox input:checked~h2 {
  color: #fff;
}

.quationpannelbox h2 {
  font-size: 13px;
  margin: 0;
  position: relative;
  z-index: 10;
  line-height: 15px;
}

.setPostionStick {
  margin-top: 5px;
}

.position-sticky {
  top: 0px;
  z-index: 100;
  background: #fff;
}

.topPosticy {
  position: sticky;
  top: 44px;
  background: #ffffff;
  z-index: 1;
  padding: 5px 0;
}

.top20 {
  top: 46px;
}

.top60 {
  top: 60px
}

.question:disabled {
  resize: none;
  border-color: #f0f0f0;
  color: #979797;
  box-shadow: none;
  min-height: 89px;
}

.howerUnderLine {
  position: relative;
}

.howerUnderLine::before {
  content: "";
  border-bottom: 1px solid $greenColor;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  transition: 0.2s;
}

.howerUnderLine:hover::before {
  left: 0px;
  right: 0px;
}

.footerPopup {
  padding: 10px 15px 20px;
}

.footerPopup .surveyButton {
  margin-top: 0px;
}

.recipientlistPannel-figure.innterSet {
  width: 25px;
  height: 25px;
}

.loaderPopup {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prelative {
  position: relative;
}

.loaderPopup img {
  width: 150px;
}

.btn-black-border {
  border: 1px solid#9e9e9e !important;
  color: #303030 !important;
}

.btn-black-border:hover {
  background-color: #535252 !important;
  color: #fff !important;
}

.autowidth {
  width: auto !important;
  padding: 9px 25px !important;
}

.justify {
  justify-content: center !important;
}

.asc {
  transform: rotate(180deg);
}

.left10.asc.sortby.dropdown {
  transform: rotate(0deg);
}

/*--loaderwork */

.loaderSurvey {
  background: #dedede;
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
  border-radius: 4px;
}

.setforReminders {
  display: flex;
  width: 230px;
  justify-content: inherit;
}

.relativebox {
  position: relative;
}

.normbox {
  position: absolute;
  right: 0;
}

.setsavebox {
  width: 170px;
  display: flex;
  justify-content: inherit;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

.survey-heading {
  height: 29px;
  width: 200px;
}

.survey-btn-heading {
  height: 40px;
  width: 100px;
  border-radius: 44px;
}

.survey-left {
  height: 400px;
  width: 100%;
}

.w100 {
  width: 110px;
}

.survey-quationCard {
  width: 100%;
  height: 170px;
}

.surveyboxPoup.newpop.show {
  padding: 20px;
  max-width: 600px;
}

.nb-btn {
  font-size: 12px !important;
}

.listDisable {
  pointer-events: none;
  opacity: 0.5;
}

.deleteiconCustome {
  width: 18px;
  height: 18px;
  text-align: center;
  justify-content: center;
}

.delete15 {
  font-size: 16px;
  margin-top: 1px;
}

.participaintHeader {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  transition: 0.3s;
}

.qansPannel {
  margin-top: 20px;
}

.btText {
  font-size: 13px;
  font-weight: bold;
  color: $PrimeryColor;
}

.borderrow {
  border-bottom: 1px solid $borderColor;
}

.bordercol {
  border-left: 1px solid$borderColor;
  border-right: 1px solid$borderColor;
}

.border_bottom {
  border-bottom: 1px solid $borderColor;
}

.bordercol .participaintHeader:hover {
  background-color: #f7f7f7;
}

span.figIcon img {
  width: 18px;
}

span.figIcon {
  margin-right: 0px;
  position: relative;
  top: -3px;
  left: -5px;
}

.w-40cent {
  width: 42%;
}

.w-10cent {
  width: 15%;
  text-align: left;
}

.w-20cent {
  width: 18%;
  text-align: left;
}

.allsurveyHeading {
  font-size: 16px;
  font-weight: bold;
  color: $PrimeryColor;
  margin: 0px;
}

.w-5cent {
  width: 10%;
  text-align: left;
}

.surveyHeading.headingSwitchTop.settopcustome {
  margin-top: 17px;
}

.quationpannelbox input:disabled+.markquation {
  border: 1px solid#e7eaf099;
}

.quationpannelbox input:disabled~h2 {
  opacity: 0.5 !important;
}

.quationpannelbox input:disabled~textarea {
  opacity: 0.5 !important;
}

.fbold {
  font-weight: 600;
  font-size: 14px;
  color: #5d6e84;
}

.submittext {
  margin: 0;
  font-size: 15px;
  pointer-events: none;
}

.blackbtn {
  color: #535252;
  border: 1px solid #9e9e9e !important;
}

.blackbtn:hover {
  background-color: #535252;
}

.manager-25 {
  width: 30%;
}


.manager-45 {
  width: 45%;
}

.manager-15 {
  width: 15%;
}

.manager-20 {
  width: 15%;
}

.manager-10 {
  width: 10%;
}

.centeraligntext {
  text-align: center;
}

.manager-74 {
  width: 74%;
}

.paramargin p {
  margin: 0px;
}

.tablemediaImg {
  float: left;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  overflow: hidden;
}

.tablemediaImg img {
  width: 100%;
}

.topupers {
  padding-top: 5px !important;
}

.tablemediaContent {
  margin-left: 36px;
  padding-top: 3px;
  display: block;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.quationPannelHead {
  border: 1px solid #e7eaf0;
  padding: 10px 15px;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quationNumber {
  float: left;
}

.quationnumberleft {
  width: 23px;
  height: 23px;
  border-radius: 20px;
  background: $greenColor;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  float: left;
}

.quationNumberText {
  margin-left: 10px;
  margin-top: 1px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #303030;
  outline: none;
  border: 1px solid $borderColor;
  padding: 5px 4px;
  border-radius: 4px;
}

.quationNumberText:disabled {
  border: none !important;
  opacity: 0.6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.quationnumberleft.circleset {
  position: relative;
  top: 5px;
}

.quationdelete {
  float: right;
}

.deleteBtn {
  float: left;
  font-weight: bold;
  padding: 6px 0px;
  cursor: pointer;
}

.deleteBtn.howerUnderLine:hover {
  color: #c01c48;
  opacity: 0.8;
}

.deleteBtn.howerUnderLine:hover::before {
  left: 0px;
  right: 0px;
  bottom: 4px;
  border-bottom: 1px solid #e01a4f;
}

.quationPannel {
  float: left;
  border-left: 1px solid#ddd;
  padding-left: 10px;
  margin-left: 10px;
}

.quationPannel span {
  margin-right: 7px;
  font-weight: bold;
}

h2.quaHeading {
  font-size: 14px;
  color: #5d6e84;
  font-weight: bold;
  margin-bottom: 5px;
}

.divrelative {
  position: relative;
}

.cursor-pointer.p-2.crossOption {
  position: absolute;
  left: 580px;
}

input.answertype:disabled {
  background: none;
  border: none;
  box-shadow: none;
  opacity: 0.7;
}

textarea.quationBox {
  padding: 10px;
  min-height: 60px;
  border-radius: 4px;
  border: 1px solid$borderColor;
  box-shadow: $boxShadow;
  color: #596a80;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  width: 100%;
  outline: none;
  height: 106px;
}

.recipientBox h2 {
  padding-right: 20px;
}

textarea.quationBox:disabled {
  opacity: 0.7;
}

.quationpannebody {
  padding: 15px;
  border-left: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  border-radius: 0px 0px 4px 4px;
  position: relative;
  float: left;
  width: 100%;
  padding-bottom: 30px;
}

.ediatquation span img {
  width: 12px;
  position: relative;
  top: -1px;
}

.ediatquation {
  background: #e7eaf0;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 4px 4px 0px 0px;
  position: absolute;
  bottom: 0;
  left: 50%;
  opacity: 0.7;
  color: $SecondryColor;
  transform: translate(-50%, 0px);
}

.selectQuationPannel {
  float: left;
  cursor: pointer;
}

.responsive-symba-scroll {
  width: 100%;
}

.restableTableBody {
  padding-bottom: 40px;
}

.cancelborder {
  border-color: #9e9e9e !important;
  color: #535252 !important;
}

.cancelborder:hover {
  background-color: #535252 !important;
  border-color: #535252 !important;
  color: #fff !important;
}

.color-danger {
  border-color: red !important;
}

h3.surveyHeading.htop {
  font-size: 16px;
  color: #303030;
  font-weight: bold;
  width: auto;
  margin: 18px 0px 5px 0px;
}

.relativeSurvey {
  position: relative;
}

.setbtnexport {
  width: 220px;
  margin-left: 10px;
}

.eventbuttonPannel.float-right {
  position: relative;
  top: -8px;
  height: 4px;
}

.eventbuttonPannel a {
  font-weight: 600;
}

.eventbuttonPannel .iconBtn {
  width: 16px !important;
}

.eventbuttonPannel .iconBtn img {
  width: 100%;
}

.setNwidth {
  width: 330px !important;
  margin-right: 9px;
}

.surveyAccordion {
  position: absolute;
  right: 0px !important;
  top: -2px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  color: #303030;
  font-size: 16px;
  transition: 0.5s;
  background-color: #e7eaf0;
}

.rotateset {
  transform: rotate(180deg);
  transition: 0.3s;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newrotate {
  background-color: $greenColor;
  color: #fff;
}

.surveyAccordion:hover {
  background-color: #46bfa5;
  color: #fff;
}

.newRelatediv {
  position: relative;
  // border-bottom: 1px solid $borderColor;
}

// .restableTableBody .newRelatediv:last-child {
//     border-bottom: none;
// }

.newrowSurvey {
  border-top: 1px solid#fff;
  position: relative;
  top: -1px;
  border-bottom: 1px solid $borderColor;
  padding: 8px 0px;
}

.newtableRowCustom {
  padding: 5px 10px 0px;
  border-radius: 4px;
  background-color: #fafbfc;
}

.newtableRowCustom .recipientlistPannel-figure.innterSet {
  width: 25px;
  height: 25px;
}

.newtableRowCustom .surveyCardRight {
  margin-left: 30px;
}

span.newstopic {
  position: absolute;
  top: 8px;
  right: 0;
  font-size: 10px;
  background: #f1f2f4;
  font-weight: bold;
  padding: 2px 4px;
  color: #a3acb8;
  border-radius: 3px;
}

.newtableRowCustom .surveyCardHeading.newtabletext {
  font-size: 12px;
  font-weight: 600;
  padding-top: 6px;
}

.newtableRowCustom .tableHeadCol {
  font-size: 12px;
}

.newtableRowCustom .tableHeadCol p {
  font-size: 12px;
}

.nopadding {
  padding: 0px 0px !important;
}

.newtableRowCustom .resTableRow {
  border-bottom: none !important;
}

.newrowSurvey.setspaceNewtable {
  padding: 0px 0px 5px 5px !important;
}

.newtableRowCustom .resCol {
  padding: 7px 0px 3px;
}

.newtableRowCustom .newRelatediv {
  padding: 0px 10px;
}

.col-340 {
  width: 39%;
}

.optionsIndex {
  display: flex;
  align-items: center;
  font-weight: 600;
  position: relative;
  top: -3px;
}

button.button.delete-button.cancelBtn.nCancilBtn.surveyNewCalcelbtn {
  padding: 8px 10px !important;
  position: relative;
  height: 36px !important;
  top: 4px;
  margin-left: 10px;
}

h2.surveyCardHeading.newtabletext {
  width: 95px;
}

h1.newScaliton.heading {
  width: 280px;
  background: #f0f0f0;
  height: 20px;
}

.newScaliton.rightsider {
  float: right;
}

p.newScaliton.parals {
  width: 210px;
  background: #f0f0f0;
  height: 20px;
  margin-top: -8px;
  display: block;
}

.widthfare80 {
  width: 80% !important;
}

.resCol.col-120.newRelatediv.lfpading0.paddingremov {
  padding: 3px 0px;
}

.tableHeadCol.marginleft15 {
  margin-left: 13px;
}

.tableHeadCol.setmarginLeft.atlastnew15 {
  margin-left: 24px;
}

.resCol.col-100.padding3 {
  padding: 3px 0px !important;
  height: 34px;
  display: flex;
  align-items: center;
  margin-top: -3px;
}

.relativepannel {
  position: relative;
}

.rightDots.childicon {
  position: absolute;
  top: -12px;
}

p.listText.mln-0 {
  margin-left: 6px;
}

h2.surveyCardHeading.newtabletext.setforname.setformhalf.widthfare80 {
  width: 110px !important;
}

input.datePicker.mt-3.formSurvey.react-datepicker-ignore-onclickoutside {
  border-radius: 4px !important;
  box-shadow: $boxShadow !important;
  border: 1px solid$borderColor !important;
}

.formSurvey {
  border-radius: 4px !important;
}

.datepickerSurvey .react-datepicker-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 1px;
  bottom: 0;
  padding-left: 69px;
  background: transparent !important;
}

.datepickerSurvey .inputStyle,
.react-datepicker__input-container input {
  background: transparent !important;
}

.datepickerSurvey {
  margin: 27px 0px;
  float: left;
  width: 100%;
  position: relative;
  border: 1px solid#ddd;
  box-shadow: $boxShadow;
  border-radius: 4px;
  cursor: pointer;
}

.flextclumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nfcanclebtn {
  font-size: 16px !important;
}

.wfull {
  width: 100%;
}

.datePicker {
  margin-top: 0px;
  border: none !important;
  box-shadow: none !important;
}

.datepickerSurvey label {
  padding: 0px;
  margin: 13px 20px 13px 13px;
  line-height: 16px;
  display: inline-block;
}

span.calanderSurveyIcon img {
  width: 100%;
}

.newRecipentButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.SureveysetWidthsurvey {
  padding: 8px 20px;
  border: 1px solid $greenColor;
  font-size: 13px;
  border-radius: 30px;
  color: $greenColor;
  transition: 0.3s;
  background-color: transparent;
}

button.SureveysetWidthsurvey:hover {
  background-color: $greenColor;
  color: #fff;
}

.addRecipientsForm.surveyboxPoup.show p {
  margin: 0px;
  padding: 0px;
}

.distypemain.setColumn {
  display: flex;
  flex-direction: column;
}

.discustiontype.sentbodersurvey {
  border-top: 1px solid#ddd;
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0px;
  padding-right: 0px;
}

.notificationtype {
  margin-top: 15px;
}

.notificationtype h3 {
  font-size: 16px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
}

.datepicforSurve {
  float: left;
  margin-left: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: relative;
  margin-top: -7px;
}

.datepickerSurvey .inputStyle,
.react-datepicker__input-container input {
  padding: 8px !important;
}

p.recipientsSelectText.mt-0p.fz-t14 {
  font-size: 14px;
  font-weight: 600;
}

#setpaddingsurvey {
  padding: 8px 0px 6px 10px !important;
  font-size: 13px !important;
}

.newRadiocheck {
  border: 1px solid #ddd;
  width: 17px;
  height: 17px;
  border-radius: 50px;
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 8px;
}

.chk input:checked~.newRadiocheck {
  background-color: #53af99;
  border: 1px solid#46BFA5;
}

.chk input:checked~.newRadiocheck .radioBox {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #46bfa5;
  border-radius: 50px;
  border: 1px solid#46bfa5;
}

span.calanderSurveyIcon {
  width: 16px !important;
  display: inline-block;
  position: absolute;
  margin-right: 3px;
  top: 12px;
  right: 8px;
  z-index: 100;
}

span.calanderSurveyIcon.forpop {
  top: 6px;
}

.sterfont {
  font-size: 14px;
  font-weight: 600;
}

label.chk.m-0.setwidthsurvey {
  width: 180px;
  padding: 0px;
}

p.recipientsSelectText.mt-0p.tf-14 {
  font-size: 14px !important;
  font-weight: 600;
}

.duDateText {
  position: absolute;
  top: 28px;
  font-weight: 600;
  font-size: 12px;
}

.projectdetails {
  margin-left: -25px;
}

.textemails {
  float: left;
  width: 100%;
}

input.datePicker.formSurvey.textalign {
  padding: 12px 0px !important;
  margin-left: 7px !important;
  font-weight: 400 !important;
  position: relative !important;
  top: -1px;
}

.w-25 {
  width: 23px !important;
}

img.paterImage {
  width: 16px;
  margin-right: 3px;
}

img.w12 {
  width: 13px !important;
  margin-left: 2px;
  height: 18px;
}

.w16 {
  width: 16px !important;
  margin-left: 1px;
  height: 16px !important;
}

a.active.nav-link .secondIcon {
  display: block;
}

a.active.nav-link .firsticon {
  display: none;
}

.secondIcon img {
  width: 100%;
}

.firsticon img {
  width: 18px;
  height: 18px;
}

.w20 {
  width: 19px;
  height: 19px;
}

.w19 {
  width: 19px;
  height: 19px;
}

.firsticon {
  float: left;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  display: none;
}

.secondIcon {
  float: left;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

a.nav-link .firsticon {
  display: block;
}

a.nav-link .secondIcon {
  display: none;
}

a.active.nav-link .iconGree {
  display: block;
}

a.active.nav-link .paterImage {
  display: none;
}

.iconGree {
  margin-right: 3px;
  width: 16px;
  display: none;
  float: left;
}

.g-13 {
  width: 13px;
}

.datepickerSurvey input {
  padding: 12px 5px !important;
  position: relative;
  top: 0px;
}

span.secondIcon img {
  width: 18px;
  height: 18px;
}

span.headingIcon.mtcutom-2 {
  position: relative;
  top: -4px;
}

.tabPannel {
  width: 100%;
  padding: 10px 20px 12px 5px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

img.greenIconSet.wparticipaint {
  width: 18px !important;
}

.tabPannel>ul {
  margin: 0;
  padding: 0;
}

.tabPannel.other>ul {
  margin: 0;
  padding: 0;
  margin-top: 16px;
}

.tabPannel>ul>li {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.tabPannel .activeTab {
  background-color: transparent !important;
  color: $greenColor;
}

.tabPannel img {
  width: 22px;
  position: relative;
  top: 1px;
}

.blackIcon {
  float: left;
  margin-right: 5px;
  display: block;
}

.greenIconSet {
  display: none;
}

.activeTab .greenIconSet {
  display: block;
  float: left;
  margin-right: 5px;
}

.activeTab .blackIcon {
  display: none;
}

.wmentor {
  width: 14px !important;
}

img.blackIcon.mintoriconblack {
  width: 21px;
}

.wparticipaint {
  width: 15px;
}

.surveySearchMain.margintopbottom {
  margin: 5px 0px 10px;
}

.flexAuto {
  display: flex;
}

.addRecipientsForm.surveyboxPoup.show+.addRecipientsForm {
  display: none;
}

.surveyHeading.headingSwitchTop.settopcustome.settopbacksurvey {
  margin-top: 15px;
}

.w500 {
  width: 500px;
}

.datesetSurvey {
  border: none !important;
  padding: 11px !important;
}

textarea.surveyName.setnewpaddingcustomesurvey {
  padding: 16px 8px !important;
}

.formGridCustUi input,
.react-datepicker__input-container input {
  padding: 11px !important;
}

.relativePostion {
  position: relative;
}

.allbullerbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100;
  display: flex;
  align-items: flex-end;
}

.alertsurveyPopup {
  text-align: center;
  padding: 20px;
  border-radius: 0px;
  background: #e01a4f;
}

.sentok {
  border-radius: 50px;
  padding: 7px 15px;
  border: none;
  color: #292929;
  width: 70px;
  background: #fff;
  font-size: 14px;
  margin-top: 9px;
}

.sentok:hover {
  background-color: #595959;
  color: #fff;
}

.alertsurveyPopup h3 {
  font-size: 18px;
  color: #ffdae4;
  font-weight: 600;
}

button.surwayborder.btn-small.btn-relative:disabled {
  pointer-events: none;
  opacity: 0.5;
}


p.listText.mln-01 {
  margin-left: 12px;
}

.surveyDorpList.inerPoupDrop.show.event {
  width: 140px !important;
  right: 10px !important;
}

li.activeTab.blackactivetabe.shoudText {
  background-color: transparent !important;
  color: #46bfa5 !important;
  cursor: pointer !important;
}

a.blackactivetabe.shoudText {
  cursor: pointer !important;
}

.backPopup {
  position: absolute;
  width: 60px;
  height: 40px;
  padding: 8px;
  cursor: pointer;
  top: 0px;
  left: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #57c5ad;
  font-weight: bold;
  font-size: 14px;
}

.backPopup i {
  position: relative;
  top: -1px;
}

.backPopup img {
  width: 100%;
}

span.closeUser img {
  width: 13px;
  padding: 2px;
  position: relative;
  top: -1px;
  right: 1px;
}

span.catalTex {
  max-width: 82px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.w-100.float-left.mt-2.matterpannel {
  max-height: 100px;
  overflow: auto;
}

.flexnewbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: inherit;
}

.flexnewbtn button {
  padding: 8px 15px;
  width: auto;
  font-size: 14px;
  min-width: 150px;
}

.surveyScrollHeight {
  height: 362px;
  overflow: auto;
  overflow-x: hidden;
}

.fz12 {
  font-size: 12px;
}

.setforname {
  margin-bottom: 0;
}

.fadeType {
  opacity: 0.4;
}

.recipientlistPannel-figure.innterSet.greenCircle.figureImg.customeForImg {
  width: 40px;
  height: 40px;
}

h2.surveyCardHeading.newtabletext.setforname.setTexttop {
  padding-top: 5px;
}

h2.surveyCardHeading.newtabletext.setforname.setformhalf.widthfare80.smalltexttop {
  padding-top: 0;
}

.recipientlistPannel-figure.innterSet.greenCircle.figureImg.smallfontimages {
  width: 30px;
  height: 30px;
}

.surveyCardRight.mrcustomeleft {
  margin-left: 36px;
}

.overdue {
  font-size: 14px;
  color: #c40000;
  border-radius: 3px;
  text-align: left;
}

.starbox {
  margin-left: 40px;
}

.starbox>ul {
  margin: 0;
  padding: 0;
}

.starbox>ul>li {
  margin-top: 10px;
  display: inline-block;
  margin-right: 10px;
}

.starbox i {
  color: #46bfa5;
  font-size: 18px;
  cursor: pointer;
}

/* The container */
.qcheckbtnNew {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.qcheckbtnNew input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.qcheckbtnNewmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid#ddd;
}

/* When the checkbox is checked, add a blue background */
.qcheckbtnNew input:checked~.qcheckbtnNewmark {
  background-color: #46bfa5;
  border-color: #46bfa5;
}

/* Create the qcheckbtnNewmark/indicator (hidden when not checked) */
.qcheckbtnNewmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the qcheckbtnNewmark when checked */
.qcheckbtnNew input:checked~.qcheckbtnNewmark:after {
  display: block;
}

/* Style the qcheckbtnNewmark/indicator */
.qcheckbtnNew .qcheckbtnNewmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.qcheckbtnNew h2 {
  font-size: 14px;
  text-transform: capitalize;
  margin-left: -8px;
  padding-top: 2px;
}

p.checkUserna.chekall.cursor-pointer {
  position: relative;
  left: 0px;
  top: 4px;
  font-size: 13px;
}

.left78 {
  position: relative;
  left: 128px;
}

.dark {
  background: #000;
  margin-right: 3px;
}

.setLauchpadDrowp {
  margin-bottom: 20px;
}

.setLauchpadDrowp .MuiInputBase-input {
  height: 25px !important;
  padding: 0 !important;
}

.setLauchpadDrowp .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -5px !important;
  font-size: 14px !important;
}

.setLauchpadDrowp .css-1mywu95-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  font-size: 19px !important;
}

.setLauchpadDrowp .css-6od3lo-MuiChip-label {
  height: 23px;
  font-size: 13px !important;
}

.setLauchpadDrowp .MuiChip-root {
  height: 24px !important;
}

.warning {
  color: red !important;
  font-size: 15px !important;
  margin: 0 0 20px 0;
  padding: 0;
}

.submtbtnnew {
  position: absolute;
  top: 5px;
  right: 0;
  font-weight: 600;
}

.addRecipientsForm {
  width: 536px;
  // height: 710px;
}

.survey.addRecipientsForm {
  width: 530px;
  // height: 640px !important;
}

.addRecipientsForm.schedule {
  height: 660px !important;
  width: 541px;
  z-index: 9999 !important;
}

.col-300 {
  width: 24.5% !important;
}

.rightDots.setrightsurvey {
  top: 0;
}

.dolerwick .col-200 {
  width: 24.5%;
}

.setLauchpadDrowp .css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-tag {
  max-width: calc(100% - 275px) !important;
}

//dev
.setLauchpadDrowp .css-18nc3u2 .MuiAutocomplete-tag {
  max-width: calc(100% - 275px) !important;
}

.input-box-single.css-18nc3u2 .MuiAutocomplete-tag {
  max-width: calc(100% - 6px) !important;
}

.newtextareabtn {
  display: block;
  margin-top: 20px;
  position: relative;
}

textarea.form-control.surveydis {
  height: 140px;
  border-radius: 5px;
  border-color: #e7eaf0;
  ;
}

.editpoupheading h2 {
  font-size: 16px;
}

p.surveyDiscription {
  width: 450px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.discriptext {
  position: absolute;
  top: 24px;
  font-weight: 600;
  font-size: 12px;
}


.relateHeadingsurvey {
  display: inline-block;
}

.surveyflex {
  display: flex;
}


.textareasetsers {
  margin-top: 30px;
}

.setforpoup {
  bottom: initial;
  bottom: -40px !important;
  right: 0 !important;
}

.setforsurveyname {
  top: 252px;
  right: 34px !important;
}

.edit-setforsurveyname {
  top: 50px;
  right: 2px !important;
}

p.surDisc {
  font-weight: 300;
  font-size: 13px;
  margin: 0px;
  padding-right: 20px;
}

.surveydisordate {
  display: block;
}

.autowidthstsetdownload {
  width: 150px !important;
}

.manager-8 {
  width: 8%;
}


.manager-6 {
  width: 6%;
}

.manager-5 {
  width: 5%;
}

.paraHading {
  max-width: 650px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  overflow: hidden;
}

.font-bold {
  font-weight: bold;
}

.surveyinfo {
  position: relative;
  cursor: pointer;
  z-index: 10;
}


.surveyinfo i {
  color: #424242;
  border-radius: 100%;
  border: 1px solid #ccc;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infocontent {
  position: absolute;
  top: 33px;
  background: #666;
  padding: 14px;
  border-radius: 5px;
  width: 500px;
  left: -50px;
  display: none;
  z-index: 100000;
}




.surveyinfo:hover .infocontent {
  display: block;
}

.infosurveyItems h3 {
  font-size: 14px;
  font-weight: bold;
}

.infopannelsurvey {
  position: relative;
  color: #fff;
}

.infosurveyItems {
  position: relative;
}

.infosurveyItems p {
  margin: 0;
  color: #fff;
}

.infopannelsurvey::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 10px solid #666666;
  position: absolute;
  top: -22px;
  left: 40px;
}

.searchboxRight.surveysetbox {
  width: calc(90% - 190px);
}

input.eventeditBox.nomarginjourney.errorBorder {
  margin: 0px;
}

.sidePopHeader.marginbottomsurvey {
  margin-bottom: 0 !important;
}

.justicficenter {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

span.surveyDatesInfo {
  font-size: 12px;
  margin: 4px 0px 9px 0px;
  display: inline-block;
  color: #666;
  font-weight: bold;
  background: #fff;
  padding: 0px 7px;
  border-radius: 3px;
}


.flexRow.position-sticky {
  padding: 0px 0px 4px;
}


.managersurveyheading {
  margin-top: 20px !important;
}

.topsurveyreduce {
  top: -3px;
  position: relative;
}

.surveysetborder {
  width: calc(100% + 30px);
}

.topPosticy.notpaddinginsurvey {
  margin-top: -2px;
}

.topPosticy.notpaddinginsurvey {
  top: 41px !important;
}

.resolvezindex {
  z-index: 98;
}


.setTableWidth {
  width: calc(100% + 35px);
}


.dublicateBtn {
  float: left;
  font-weight: bold;
  padding: 6px 0px;
  cursor: pointer;
  position: relative;
}

.dublicateBtn em {
  color: #46BFA5;
}

.dublicateBtn:hover {
  color: #46bfa5;
  opacity: 0.8;
}

.dublicateBtn.howerUnderLine:hover::before {
  left: 0px;
  right: 0px;
  bottom: 4px;
  border-bottom: 1px solid #46bfa5;
}

.custome-3 {
  width: 25%;
  padding-left: 30px;
}

.custome-9 {
  width: 75%;
}

.pannelfexsurvey {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.journeyIcon {
  height: 30px;
}

.journeyListleft {
  float: left;
  width: 100%;
  margin-left: 10px;
  font-size: 15px;
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

// -----auto assign----
.autoassign {
  padding: 9px 15px 0px 17px;
}

.autoassign-header p {
  text-align: left;
  color: #000000;
  font-weight: 600 !important;
}

.autoassign-header p {
  text-align: left;
  color: #000000;
  font-weight: 600 !important;
  margin: 5px 0px 8px;
}

.participaintCol {
  padding-left: 10px;
  color: $PrimeryColor;
  font-size: 14px;
}

.tableSurveyPraticipant.responsive-symba-scroll {
  width: calc(100% + 34px) !important;
}

.sureveyTab {
  display: flex;
  width: 100%;
  align-items: center;
}

.sureveylist {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.sureveylist:hover {
  opacity: 0.5;
}

.surveyFigIcon {
  width: 30px;
  padding: 5px
}

.activeSurvey b {
  color: #46BFA5;
}

.togglebuttonPannel {
  float: left;
  width: 118px;
  margin-right: 10px;
  border-right: 1px solid #ddd;
  display: block;
  padding: 4px 0px;
}




.togglebuttonPannel .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
  margin: 0px;
}

.togglebuttonPannel .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.togglebuttonPannel .slider {
  position: absolute;
  cursor: pointer;
  top: 1px;
  left: 0;
  right: 0;
  bottom: -1px;
  background-color: #DFDFDF;
  transition: 0.4s;
}

.togglebuttonPannel .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.togglebuttonPannel input:checked+.slider {
  background-color: #46BFA5;
}

.togglebuttonPannel input:focus+.slider {
  box-shadow: 0 0 1px #46BFA5;
}

.togglebuttonPannel input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.togglebuttonPannel .slider.round {
  border-radius: 34px;
}

.togglebuttonPannel .slider.round:before {
  border-radius: 50%;
}

.togglebuttonPannel .texttoggle {
  display: inline;
  position: absolute;
  left: 50px;
  font-weight: bold;
  padding: 1px 0px;
  cursor: pointer;
  margin: 0;
}

.required_question {
  color: red;
  padding: 2px;
  font-size: 16px
}

.surveyQuationReq {
  font-size: 20px;
  margin-left: 1px;
  width: 17px;
  height: 24px;
  text-align: center;
  color: #e00;
  display: inline;
  line-height: 0;
}

.journeyInlineblock {
  display: inline-block !important;
}

.padderIner.large {
  padding: 0px 15px 40px;
  border-bottom: 1px solid #ddd;
}

.datepicforSurve.false,
.datepicforSurve.true {
  float: left;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: relative;
  width: 140px;
  margin: 0;
}

.topictimewidth {
  width: 110px;
}

.topicdatewidth {
  width: 207px;
}

.send-future {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 11px;
}

.otherOption {
  position: relative;
  left: 10px;
}

.otherInput {
  padding: 7px;
  border: none;
  border-radius: 0 !important;
  border-bottom: 1px solid #9f9f9f;
  border-radius: 4px;
  font-size: 14px;
  width: 60%;
  outline: none;
}

.posi {
  position: relative;
  top: -10px;
}


.surveyNewCArd {
  border-top: 10px solid #5D6E84;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.surveyIcon {
  margin-right: 10px;
}

.flexAncor {
  display: flex;
  align-items: flex-start;
}

.surveyNewCArd .surveyCardDropdown {
  top: 26px;
  right: 30px;
}

.surveyIcon img {
  width: 20px;
}

.selectAllmanage {
  position: relative;
  left: 80px;
}


.tablebody.setforscroll.symba-scroll.prelative {
  max-height: calc(100vh - 460px);
}

.assignheadingarea {
  float: left;
  width: 100%;
  margin: 6px 0px 0px;
  padding: 0px 20px 0px;
}

.assignheading {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 7px;
}


.surveyEditbtn:hover {
  background-color: #46bfa5;
  color: #fff;
}

.assignheadingarea {
  float: left;
  width: 100%;
  margin: 13px 0px 0px;
  padding: 0px 20px 0px;
}

.assignheading {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 7px;
}

.respondBox {
  width: 100%;
  max-width: 300px;
}

.setwidthsurveyicon {
  width: 20px !important;
}


.surveygpr {
  float: left !important;
  width: 100% !important;
  margin: 5px 0px 0px !important;
  padding: 7px 20px 10px !important;
  background: #46bfa52e !important;
  border-radius: 5px !important;
  align-items: center !important; 
  justify-content: center !important;
  display: flex;
}


.surveygpr .assignheading {
  width: 150px;
  padding: 0px;
  margin: 4px 0 0 0;
}

.totaluserSuvey {
  font-size: 16px;
  font-weight: bold;
  margin: 12px 0px 0px;
}

.surveygpr .filterbox {
  background-color: #fff !important;
  min-width: 34px;
  margin-top: 4px;
  position: relative;
  border-color: #555555;
  margin-right: 10px !important;
  margin-bottom: 0px !important;
}

.surveygpr .filterbox span {
  position: absolute;
  top: -9px;
  right: -5px;
}

.assignheadingarea.surveygpr {
  background: #f3f3f3 !important;
}

.surveygpr .filterbox p {
  color: #555555;
}

.searchsurveymidbox input {
  padding: 0px 0px 0px 30px;
  border: 1px solid $borderColor !important;
  border-radius: 4px;
  box-shadow: $boxShadow !important;
  padding: 9px 0px 8px 28px;
}

.searchsurveymidbox {
  width: 100%;
  position: relative;
  justify-content: space-between;
  text-align: center;
}


.d-flex.flexalignrigh {
  justify-content: space-between;
}

.addrecipientbent {
  border: 1px solid #46bfa5;
  margin: 0;
  padding: 7px 15px;
  border-radius: 50px;
  color: #46bfa5;
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s;
}

.addrecipientbent:hover{background-color: #46bfa5; color: #fff;}



@media screen and (max-width: 1024px) {
  .col-120 {
    width: 26%;
  }
}

@media screen and (max-width: 830px) {
  .setNwidth {
    width: 340px !important;
  }

  .addRecipientsForm {
    width: 100%;
  }

  .setW-750 {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 565px) {
  .innertx:hover .iconBtn.nextPage.hover {
    display: inline-block;
    float: none;
  }

  .top20 {
    top: 0px;
  }

  .proptPupup {
    max-width: calc(100% - 30px);
  }

  .searchboxRight {
    width: 100%;
  }

  .flexRow {
    flex-direction: column;
  }

  .searchboxleft {
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .midlePointSurvey-heading {
    text-align: center;
  }

  .searchboxleft button {
    width: 100%;
  }

  main {
    margin-top: 0px !important;
  }

  .surveyMainBody {
    width: 100%;
  }

  .surveyboxPoup {
    padding: 20px 15px;
    width: calc(100% - 15px);
    overflow: scroll;
    height: 550px;
  }

  .deleteMain {
    min-width: 100%;
    width: 100%;
  }

  .surveyOtherbox {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .userSec {
    width: 100% !important;
  }

  .surwaybody {
    flex-direction: column;
    margin-top: 0px;
  }

  .surveyBox {
    width: 100%;
    margin-top: 10px;
  }

  textarea.surveyName {
    height: 250px;
  }

  .quationPannelHead {
    flex-direction: column;
  }

  .quationNumber {
    float: none;
    width: 100%;
    margin-bottom: 10px;
  }

  .quationdelete {
    float: none;
    width: 100%;
  }

  .deleteBtn {
    width: 40%;
  }

  .quationPannel {
    width: 56%;
    text-align: right;
  }

  .selectQuationPannel {
    width: 88%;
  }

  .quationNumberText {
    width: 100%;
  }

  .surveyboxPoup.newpop.show {
    max-width: 320px;
    top: 50px;
    left: 15px;
    transform: translate(0px, 0px);
    padding: 20px 15px;
    height: auto;
  }

  .askPannelleft.position-sticky.top20 {
    margin-top: 20px;
  }

  .answertype {
    width: 180px !important;
  }

  .w30 {
    width: 300px;
  }

  .addRecipientsForm {
    width: 100%;
  }

  .circle {
    width: 30px;
    height: 30px;
  }

  .position-sticky,
  .topPosticy {
    position: inherit !important;
  }

  .cursor-pointer.p-2.crossOption {
    position: absolute;
    left: 280px;
  }

  .surveyboxPoup.delete.show {
    height: auto;
  }

  .responsive-symba-scroll {
    overflow: auto;
  }

  .responsiveScroll {
    width: 1200px;
  }

  .w-1500 {
    width: 1500px;
  }

  .surwayborder.btn-small {
    width: 140px;
  }

  .respondBox {
    margin-bottom: 10px;
  }

  .borderpadding {
    padding: 17px 20px 3px 20px;
  }

  a.saveBtn.mt-1.btn-save.innertx {
    width: 100%;
  }

  a.saveBtn.mt-1.btn-save.innertx.iconBtn.nextPage {
    display: none;
  }

  .responsivetable {
    width: 890px;
  }

  .eventTable {
    overflow: auto;
  }

  .admin .wevent-70 {
    width: calc(100% - 127px) !important;
  }

  .eventRow {
    margin: 41px -5px;
  }

  button.newCreatebtn.eventbutt {
    position: relative;
    top: 85px;
    width: 100%;
    right: 0;
  }
}


