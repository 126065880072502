/*--------newLaunchpad-design-------*/
.newLuanchpadmain {
    float: left;
    width: calc(100% + 40px);
    height: 100%;
    background: #FCFCFC;
    margin-left: -20px;
    padding: 20px;
    padding: 0px 20px 20px 20px;
    justify-content: space-between;
    display: flex;
}

.headingheaderpoup {
    margin-top: 5px;
    padding: 20px;
}

.bellIcon img {
    width: 100%;
}

.bellIcon {
    float: left;
    width: 23px;
    position: relative;
    top: 0px;
}

.announcementTab {
    float: left;
    width: 100%;
    border: 1px solid#E7EAF0;
    padding: 8px 15px 15px 15px;
    border-radius: 8px;
    margin-top: 5px;
    background-color: #fff;
}

.annoucementpannel h2 {
    color: #0C2649;
    font-size: 15px;
    font-weight: 600;
    margin: 5px 0px 0px 28px;
}

.annouceleftPannel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.greenannoucebtn {
    background: #46BFA5;
    color: #fff;
    border: 1px solid #46BFA5;
    padding: 5px 12px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 12px;
}

.greenannoucebtn:hover {
    background-color: #23a186;
}

.likebtnannouce .likeAnnoucement {
    margin: 0;
    font-size: 12px;
    color: #46bfa5;
}

.annoucementpannel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttonannoucement {
    margin-left: 30px;
}

.likeAnnoucement {
    margin: 0;
    color: #46bfa5;
    border-bottom: 1px solid#46bfa5;
    cursor: pointer;
}

.likebtnannouce {
    // box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1);
    padding: 8px 6px;
    border-radius: 50px;
    cursor: pointer;
}

.likebtnannouce:hover .likeAnnoucement {
    border-bottom: 1px solid#f7f7f7;
}

.imageAnnce {
    width: 35px;
    height: 35px;
    float: left;
}

.imageAnnce img {
    width: 100%;
    cursor: pointer;
}

.annceHeadText {
    margin-left: 43px;
    display: flex;
    align-items: self-start;
    flex-direction: column;
    justify-content: center;
    height: 36px;
}

span.annouceSubLightText {
    font-size: 10px;
}

.annceHeadText h2 {
    font-size: 14px;
    font-weight: bold;
    color: #0C2649;
    margin-bottom: 0px;
}

.dateBold {
    font-weight: bold;
    color: #8F95B2;
    text-transform: capitalize;
    font-size: 12px;
}

.annouceListPannel {
    border: 1px solid#E7EAF0;
    padding: 12px 15px;
    margin-top: 8px;
    border-radius: 8px;
    position: relative;
    width: 100%;
    float: left;
}


span.userName {
    font-size: 12px;
    background: #EBEFFD;
    padding: 2px 10px;
    border-radius: 5px;
    margin-left: 5px;
}

.annoucebtnbottompannel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ebeffd;
    border-radius: 5px;
    padding: 3px 10px;
    margin-top: 12px;
    cursor: pointer;
}

.annoucementbottombttton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.setAnnoucebtn {
    margin-left: 5px;
    font-weight: bold;
    color: #596A80;
}

.imageannoucebanner {
    margin: 5px 0px 14px;
    padding: 10px 0px;
    background: #f6f6f6;
    border-radius: 5px;
}

.imageannoucebanner img {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
}

.newLaunchpadleft {
    width: 30%;
    padding-left: 20px;
    margin-top: 10px;
}

// .nolink {
//     cursor: default !important;
// }

.toppannelnewlaunch {
    border: 1px solid #E7EAF0;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: #fff;
}

.profileheadinglaunch {
    margin-top: 15px;
    margin-bottom: 0px;
    font-size: 13px;
    color: #303030;
    font-weight: 400;
}

.greenmaintext {
    color: #46BFA5;
}

span.dateBold.datebg {
    background: #5D6E84;
    color: #fff;
    padding: 1px 4px;
    font-size: 10px;
    border-radius: 3px;
    font-weight: 300;
    margin-top: 2px;
}

.prolaunchbar {
    width: 100%;
    height: 12px;
    background: #F0F1F6;
    border-radius: 50px;
    position: relative;
}

.greanBarprolaun {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #46bfa5;
    border-radius: 50px;
}

.progressbarLaunchpad {
    margin-top: 10px;
}

.setHeaimg {
    float: left;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setHeaimg img {
    width: 20px;
    height: 20px;
}

.headingtoppennel {
    font-size: 14px;
    margin: 0px 0px 0px 31px;
    font-weight: 600;
    color: #0C2649;
    height: 32px;
    line-height: 32px;
}

.calanderIcons {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jurneytab.launchpadannouce {
    display: block;
}

.jurneytab.launchpadannouce li.active::before {
    height: 1px !important;
    bottom: -1.5px !important;
}

.datafontCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    font-size: 12px;
}


.sideBarMargin {
    margin-top: 15px;
}

.admitLeft {
    margin-left: 0 !important;
}



.surveyRadio {
    display: block;
    position: relative;
    margin-bottom: 24px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.surveyRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmarkRadio {
    position: absolute;
    top: 2px;
    left: 5px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid#4C5F7E;
}

/* On mouse-over, add a grey background color */
.surveyRadio:hover input~.checkmarkRadio {
    background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.surveyRadio input:checked~.checkmarkRadio {
    background-color: #4C5F7E;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkRadio:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.surveyRadio input:checked~.checkmarkRadio:after {
    display: block;
}

/* Style the indicator (dot/circle) */
// .surveyRadio .checkmarkRadio:after {
//     top: 9px;
//     left: 9px;
//     width: 8px;
//     height: 8px;
//     border-radius: 50%;
//     background: white;
// }


.radiobtnleft {
    width: 30px;
    height: 30px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radiocontent p {
    margin: 0px 0px 0px 1px;
    font-size: 12px;
    font-weight: 600;
    color: #303030;
}

.actionRadio {
    margin-top: 10px;
}

.radiocotSet {
    display: inline-block;
    padding: 3px 8px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
    margin-right: 10px;
}

.redbg {
    background: rgba(224, 26, 79, 0.12);
    color: #E01A4F;
}

.yelowbg {
    background: rgba(255, 167, 5, 0.12);
    color: #FFA705;
}

.greybg {
    background: rgba(205, 205, 205, 0.2);
    color: #8f8f8f;
}




.newlauchpadfont {
    font-size: 16px;
    color: #101840;
    font-weight: 700;
    float: left;
    width: 100%;
    margin: 10px 0 0;
}

.tabtdxt {
    color: #596A80;
    font-size: 14px;
    font-weight: bold;
    margin-left: 43px;
    margin-top: 17px;
    margin-bottom: 15px;
}

.tablistnewLaunchpad {
    position: relative;
    margin-top: 5px;
}

.tablistnewLaunchpad>ul>li {
    display: inline-block;
    padding: 0px 10px 0px 0px;
}


.tablistnewLaunchpad::before {
    content: "";
    width: 99%;
    height: 2px;
    background: #E7EAF0;
    position: absolute;
    top: 14px;
    left: 4px;
}

.tablistnewLaunchpad::after {
    content: "";
    width: 8px;
    height: 8px;
    background: #E7EAF0;
    position: absolute;
    top: 11px;
    left: 4px;
    border-radius: 50px;
}

.tablbtnlaunch {
    background: #fff;
    border: 1px solid#EBEFFD;
    border-radius: 6px;
    padding: 5px 15px;
    color: #4C607E;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tablbtnlaunch.active {
    background: #46BFA5;
    color: #fff;
    border: 1px solid #46BFA5;
}

.newlaunchpadflexstart {
    width: 70%;
    padding-top: 10px;
}


.listofCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.listcard {
    background: #f0f0f0;
    border-left: 5px solid #596A80;
    border-radius: 5px;
    padding: 4px 0px 3px 0px;
    margin-bottom: 10px;
    cursor: pointer;
    height: 36px;
}

.listofUser {
    margin-top: 0px;
    overflow: auto;
    max-height: 370px;
}



.listcardIcons {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

// .listcardIcons img {
//     width: 30px;
// }

.listofCard h3 {
    font-size: 12px;
    position: relative;
    margin-top: 0px;
    line-height: 16px;
    margin-left: 0px;
    font-weight: 600;
    margin-bottom: 0px;
}

.Eventviewbtn {
    font-size: 13px;
    color: #46BFA5;
    font-weight: bold;
    padding: 5px 0px 0px;
}

.Eventviewbtn span {
    border-bottom: 1px solid#46BFA5;
    cursor: pointer;
    font-size: 13px;
}

.percentage {
    font-weight: bold;
    color: #303030;
}

.Eventviewbtn span:hover {
    opacity: 0.7;
}

.listAnnoucetext {
    font-size: 16px;
    font-weight: 300;
    color: #0C2649;
}

.teamMemberPannel {
    margin-top: 10px;
}

.teamMemberPannel>ul {
    margin: 0px;
    padding: 0px;
}

.setWdithforTeam {
    max-width: 182px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    display: inline-block;
}

.fontlimitationCalander {
    max-width: 182px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    display: inline-block;
}

.teamMemberPannel>ul>li {
    margin: 0px 0px 10px 0px;
    list-style: none;
    display: inline-block;
    width: 100%;
}

.fontnormal16 {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #0C2649 !important;
    position: relative;
    top: 2px;
}

.dateBold.light {
    font-weight: 400 !important;
}

.userName.adminMor {
    position: relative;
    left: 36px;
    top: -3px;
    font-weight: bold;
}

.announcementScroll {
    overflow: auto;
    max-height: 315px;
}

.annouceDetailPoup {
    position: fixed;
    z-index: 1000;
    background: #fff;
    width: 450px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 15px;
    border-radius: 5px;
}

.setmx800 {
    width: 900px;
}

.annouceHaderDetailsHead {
    position: relative;
    text-align: center;
}

.annoucSetoff {
    position: absolute;
    cursor: pointer;
    right: 0px;
    top: 0px;
    top: -7px;
}

.annouceHading {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.annouceSubHading {
    font-size: 14px;
    font-weight: 600;
}

.annouceBody {
    padding: 10px 0px;
}

.annouceIpu {
    display: block;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.annouceIpu label {
    display: block;
    margin-bottom: 3px;
}

.annouceIpu textarea {
    width: 100%;
    height: 100px;
    border: 1px solid#ddd;
    // box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2) !important;
    padding: 10px;
    outline: none;
    border-radius: 5px;
}


.annouceIpu input {
    border: 1px solid#ddd;
    // box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2) !important;
    padding: 17px 10px;
    width: 100%;
    outline: none;
}


.fileoptionAnnouce {
    border: 1px solid#ddd;
    box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2) !important;
    padding: 10px;
    width: 100%;
}

.fileLink {
    border: 1px solid#ddd;
    box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2) !important;
    padding: 7px 10px;
    width: 100%;
    margin-bottom: 10px;
}

.newattachment {
    border: 1px solid#ddd;
    box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2) !important;
    padding: 10px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
}

.serLine {
    position: absolute;
}

.annoucefooter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.serLine {
    position: absolute;
    right: 0;
    top: 0;
    color: #46bfa5;
    font-weight: 600;
    cursor: pointer;
}

.serLine:hover {
    border-bottom: 1px solid#46bfa5;
}

.nAnnouce {
    padding: 7px;
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    border: none;
    border-radius: 50px;
    width: 75px;
    cursor: pointer;
}

.nAnnouce:hover {
    opacity: 0.8;
}

.backbtn {
    background-color: #596A80;
    margin-right: 10px;
}

.submitbtn {
    background-color: #46BFA5;
}



.annouceIpu input[type="file"] {
    position: absolute;
    top: -1000px;
}


.imageIconpus {
    width: 22px;
    height: 22px;
    display: flex;
    position: absolute;
    top: 35px;
    right: 10px;
    cursor: pointer;
}

.imageIconpus img {
    width: 100%;
}

span.attamentlable {
    margin-bottom: 7px;
    display: block;
}


.selectedCommunity {
    background: #eee;
    border-radius: 21px;
    padding: 2px 10px;
    float: left;
    margin-right: 6px;
    margin-bottom: 2px;
    position: relative;
    top: 2px;
    height: 28px;
}

.closeUser {
    position: relative;
    right: -5px;
    cursor: pointer;
}

.closeUser svg {
    width: 14px;
}

input.searchName {
    border: none !important;
    box-shadow: none !important;
}


.setpaddingannouce {
    padding: 0px !important;
}

.comunitypannel>ul,
.searchUser ul {
    margin: 0;
    padding: 0;
}

.comunitypannel>ul>li,
.searchUser>ul>li {
    list-style: none;
    cursor: pointer;
}

.topbr {
    padding: 6px 10px;
}

.searchUser>ul>li:hover {
    background-color: #f6f6f6;
}


.dataDisLength {
    max-width: 800px;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    color: #0C2649;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.announcementPostion {
    top: 7px;
    right: 17px;
}

.launchpadMain {
    float: left;
    width: 100%;
}

.headinglaunchSubpage {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.dotslauch {
    display: block;
    width: 15px;
    height: 15px;
    background: transparent;
    border: 3px solid#fff;
    border-radius: 25px;
    margin-right: 6px;
}

.tablistnewLaunchpad ul {
    display: flex;
}

.launchpadDetailMain {
    float: left;
    width: calc(100% + 40px);
    height: 100%;
    background: #FCFCFC;
    margin-left: -20px;
    padding: 20px;
    padding: 0px 20px 20px 20px;
}

.lauchOnboardingPannel {
    background: #fff;
    border: 1px solid#E7EAF0;
    border-radius: 5px;
    padding: 20px 10px;
    min-height: 200px;
}

.annouceIpu.viaMarg {
    margin: 15px 0px 50px;
}

h2.onboardTaskHeading {
    font-size: 16px;
    font-weight: bold;
    color: #0C2649;
}

.launchMediaLeft {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    background: rgba(70, 191, 165, .2);
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
}

.launchMediaRight {
    margin-left: 70px;
}

.launchMediaRight h3 {
    font-size: 16px;
    font-weight: bold;
    color: #0C2649;
    margin-bottom: 5px;
}

.launchMediaRight p {
    color: #0C2649;
    font-weight: 600;
}

.taskComplitbtn {
    border: 1px solid#46BFA5;
    background: #fff;
    padding: 6px 15px;
    border-radius: 50px;
    color: #46BFA5;
    font-weight: bold;
    cursor: pointer;
    transition: .5s;
}

.taskComplitbtn:hover {
    background: #46BFA5;
    color: #fff;
}

.pannelJourneyPoits .profileheadinglaunch {
    margin-top: 15px !important;
}

.pointsHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headingPointLauch {
    font-size: 16px;
    font-weight: 600;
    color: #0C2649;
    margin-bottom: 0px;
}

.redim {
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    position: relative;
    top: 0px;
    border-bottom: 1px solid#46BFA5;
}

.meadiaLeftweekpoint {
    float: left;
    width: 30px;
}

.meadiaRightweekpoint {
    margin-left: 40px;
}

.headingmediaright {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
}



.userCoutAnnoucement {
    position: relative;
    float: right;
    top: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #4c5f7e;
}

.announcementtabList ul {
    margin: 0;
}

.announcementtabList ul li {
    display: inline-block;
    padding-right: 10px;
}

.announcementtabList .annnmentTab {
    display: block;
    padding: 6px 12px 6px 12px;
    color: #4C607E;
    font-weight: bold;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid#EBEFFD
}

.annnmentTab.active {
    background: #46BFA5;
    border: 1px solid#46BFA5;
    color: #fff;
}

.announceDflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.allAnnoucement {
    text-align: center;
    margin-top: 40px;
    padding: 10px 140px;
}

.allAnnoucement h3 {
    font-size: 16px;
    font-weight: bold;
    color: #4C607E;
}

.allAnnoucement p {
    font-size: 14px;
    font-weight: bold;
    color: #4C5F7E;
}

.tabDisc>ul {
    margin: 0;
    padding: 0;
}

.tabDisc>ul>li {
    display: inline-block;
    margin: 5px 0px 0px 0px;
    padding: 2px 30px 2px 0px;
    border-radius: 50px;
    cursor: pointer;

}

.tabAction {
    color: #46BFA5;
    font-weight: bold;
}

.tracText.card-subtitle {
    color: #0C2649;
    font-size: 14px;
    margin-top: 6px;
    font-weight: 400;
}

.footerEmojis>ul {
    margin: 0px;
    padding: 0;
    display: flex;
    align-items: flex-start;
}

.footerEmojis>ul>li {
    display: flex;
    justify-content: center;
    position: relative;
}

.likebuttontopic.marginHub {
    margin-top: 2px;
}

.listhub {
    margin: 2px 6px 0px 0px;
}

.announcementTab .footerEmojis .comment-emoji {
    position: absolute;
    z-index: 99;
    top: 50px;
    left: 0 !important;
}

.announcementTab .footerEmojis .leftEmojis {
    position: static !important;
    margin-top: -1px !important;
}

.rightcotent {
    margin: 0px;
    margin-left: 5px;
    color: #596A80;
    font-size: 14px;
    line-height: 28px;
    position: relative;
    top: -1px;
}

.annouceListPannel .rightcotent {
    top: -1px !important;
}

.nowrapset {
    top: 2px !important;
}

.viewSquare {
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 2px 10px;
    background: #f4f4f4;
    font-size: 10px;
    cursor: pointer;
    position: relative;
    top: 5px;
    left: 5px;
}

.viewSquare:hover {
    opacity: 0.5;
    color: #596A80;
}

.inputSelectfile,
.linkInput {
    width: 100%;
    display: block;
    border: 1px solid#ddd;
    padding: 10px;
    margin-bottom: 10px;
}

.loaderAnnoucement {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.annoucementRelative {
    position: relative;
}

.loaderAnnoucement img {
    width: 180px;
}

.listcardIconBottom {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
    margin-right: 3px;
}

.topmargin15 {
    margin-top: 33px;
}


.jurneytab.launchpadannouce>li {
    text-align: center;
    width: 50% !important;
    margin-right: 0px !important;
}

.jurneytab.launchpadannouce>li {
    font-size: 13px;
}

.cardgraBg {
    background-color: #F9F9F9 !important;
}


.AnnoucementHeading {
    padding-left: 10px;
    float: left;
    width: 100%;
}



.grayCard {
    background-color: #F3F4F7 !important;
}

.greenCard {
    background-color: rgba(236, 249, 246, 1) !important;
    border-color: #46BFA5 !important;
}

.yellowCard {
    background-color: rgba(255, 167, 5, 0.12) !important;
    border-color: #FFA705 !important;
}

.managerInternicon img {
    width: 36px;
}

.managerInternicon {
    margin-left: -3px;
}


.inputTypes {
    min-height: 36px !important;
}

.shadebox.eventShad.show {
    z-index: 1001 !important;
}

.announcementTab.hubDetail {
    border: none;
    margin-top: 0;
    padding: 8px 0px;
}

.annouceDataFound {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
    height: 100px;
}

.backsetAnnoucement {
    position: absolute;
    top: 50px;
    left: 176px;
    display: block;
}

.actionPannel {
    position: relative;
    left: -8px;
}

.calanderIconsPannel {
    width: 22px !important;
    height: 22px !important;
}

.pannelLeftcheckAnnoucement {
    float: left;
    margin-top: 1px;
    position: relative;
}

.rightAnnoucementText {
    margin-left: 24px;
}

.dashboardVideo .Engagereact-player {
    margin-top: 10px !important;
    height: 100% !important;
    width: calc(100% - 34px) !important;
}

.twoLineElips {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: auto;
}

.tracText p {
    max-width: 800px;
    font-size: 12px;
    font-weight: 400;
    color: #0C2649;
    margin: 0;

}

.tracText p:hover {
    opacity: 0.7;
}

.barGreen {
    background: #46bfa5 !important;
}

.barRed {
    background: #E01A4F !important;
}

.barYellow {
    background: #ffc107 !important;
}

.bellIconAnnocement {
    width: 28px !important;
    height: 28px !important;
    position: relative;
    left: -4px;
    top: -2px;
}


.annouceListPannel .footerEmojis {
    margin-left: 43px;
    margin-top: 4px;
    float: left;
    width: 100%;
    cursor: pointer;
}

.leftEmojis {
    position: relative;
    top: -1px;
}


.ectSpace {
    margin-top: 5px;
}


.listcard:hover {
    opacity: 0.7;
}

.alignmentBoard {
    display: flex;
}

s .overflowactionitem {
    max-height: 300px;
    overflow: auto;
    overflow-x: hidden;
}

.lefftpannelengage {
    float: left;
}

.pannelLeftcheckAnnoucement .chk {
    cursor: inherit !important;
}

/*--------newLaunchpad-design-end------*/

.setHeaimg.setNimage img {
    width: 28px;
    height: 28px;
    margin-left: -3px;
}

.colorhax {
    color: #7B61FF !important;
}

.haxcolor {
    background-color: #DAF2ED !important;
}

.haxcolor .annouceListPannel {
    background-color: #fff !important;
}

.announcementTab.hubDetail.haxcolor {
    padding: 15px;
    margin-top: 20px;
}

.ancorcolor {
    color: #674DEB !important;
    border-bottom: 1px solid #674DEB;
}

.ancorcolor:hover {
    border-bottom: 1px solid #674DEB !important;
}

.leftEmojis.viewIconstatus img {
    position: relative !important;
    left: 2px;
}

.smallScreen {
    display: none;
}

.overflowactionitem {
    height: 250px;
    overflow: auto;
}

.emogisResponsive {
    display: none;
}

.showMoretext {
    color: #46BFA5;
    text-decoration: underline;
    cursor: pointer;
    float: right;
}

.commentfooter.centerPannel {
    justify-content: center;
}

.allClosebtn {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
}

.HubCommentPannelHead {
    margin-bottom: 13px;
}

.HubCommentPannelBoddy textarea {
    width: 100%;
    box-shadow: 0 2px 4px hsla(0, 0%, 80.4%, .2) !important;
    outline: none;
    border: 1px solid#ddd;
    border-radius: 5px;
    height: 130px;
    padding: 4px 10px 10px 6px;
}

.charactorPending {
    font-size: 12px;
    color: #303030;
    position: relative;
    top: -3px;
    margin: 0px;
}

.HubCommentPannelfooter {
    text-align: center;
    margin-bottom: 10px;
}

.HubCommentPannel {
    position: fixed;
    z-index: 1000;
    background: #fff;
    width: 470px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px 20px;
    border-radius: 5px;
}

.hubButtoncomment {
    width: 100%;
    border: 1px solid#46BFA5;
    color: #46BFA5;
    background: #fff;
    border-radius: 50px;
    padding: 7px 0px;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
}

.hubButtoncomment:hover {
    background-color: #46BFA5;
    color: #fff;
}



.hubcommentArea {
    display: flex;
    width: 100%;
    margin: 10px 0px;
    float: left;
}

.humImgPicture {
    cursor: pointer;
}

.hubTextarea {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 47px;
    position: relative;
}

.hubTextarea textarea {
    width: 100%;
    border: 1px solid #C4C4C4;
    padding: 14px 24px;
    border-radius: 30px;
    font-size: 14px;
    margin-right: 10px;
    overflow: hidden;
    outline: none;
    height: 50px;
    resize: none;
}


// .hubTextarea textarea::placeholder {
//     color: #C4C4C4;
//     opacity: 1; /* Firefox */ 
//   }

//   .hubTextarea textarea::-ms-input-placeholder { /* Edge 12-18 */
//     color: #C4C4C4;

//   }

.cancle-comment {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
}

.commenttextnamefile {
    position: absolute;
    left: 40%;
    display: block;
    max-width: 177px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hubcomment:hover {
    background-color: #46BFA5;
    color: #fff;
}

.hubcomment {
    border: 1px solid #46BFA5;
    color: #46BFA5;
    background: #fff;
    border-radius: 50px;
    padding: 7px 18px;
    font-size: 14px;
    margin-left: 12px;
    cursor: pointer;
}

.hubtextcommentarea p {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
}

.hubtextcommentarea {
    width: 100%;
    background: #f0f0f0;
    margin-left: 10px;
    padding: 10px;
    border-radius: 0px 5px 5px 5px;
}

.humbCommentuser {
    display: flex;
    float: left;
    width: 100%;
    margin: 10px 0px;
}

.loadHubcomment {
    font-size: 14px;
    font-weight: bold;
}

.annceleftpannelannce {
    cursor: pointer;
}

.width50 {
    width: 50%;
}

.width65 {
    width: 65%;
}

.width60 {
    width: 60%;
}

.width55 {
    width: 55%;
}

.width25 {
    width: 25%;
}

.width30 {
    width: 30%;
}

.width25 {
    width: 25%;
}

.width40 {
    width: 40%;
}

.width20 {
    width: 20%;
}

.width25 {
    width: 25%;
}

.width15 {
    width: 15%;
}

.width35 {
    width: 35%;
}

.hubPannelgrid {
    display: flex;
    border-left: 3px solid#E01A4F;
    padding: 8px 10px;
    border-radius: 5px;
    background: #F9F9F9;
    margin-top: 5px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.hubHeadingText {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    position: relative;
    top: 4px;
}

.hubPara {
    font-size: 12px;
    font-weight: bold;
    color: #979797;
}

.hublistname {
    display: inline-block;
    margin-left: 26px;
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.adminhublistname {
    display: inline-block;
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.hublinkIcons {
    margin-top: -15px;
}


.leftAligntext {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.testRedHub {
    color: #E01A4F;
}

.hubrowPannel {
    display: flex;
    padding: 0px 10px;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px 6px;
    position: sticky;
    top: 0px;
    background: white;
    z-index: 999;
}

.rowTextHeading {
    color: #5D6E84;
    font-weight: 600;
    margin: 0px;
}

.listthumb.commentSub {
    margin-left: 10px;
}

.calanderhublink {
    float: right;
    color: #46bfa5;
    font-weight: bold;
}







.pointernone .hubPannelgrid,
.pointernone .chekhub {
    cursor: default !important;
}


.hubPannelgrid.hubgreenborder {
    border-color: #46BFA5;
}

/* The container */
.hubPannelgrid.hubgreenborder:disabled {
    cursor: default !important;
}

.chekhub {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
}

/* Hide the browser's default checkbox */
.chekhub input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.chekhub .checkmark {
    position: absolute;
    top: 2;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #D9D9D9;
    border: 1px solid #979797;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.chekhub:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chekhub input:checked~.checkmark {
    background-color: #fff;
    border-color: #46BFA5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.chekhub .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.chekhub input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.chekhub .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid #46BFA5;
    border-width: 0 2px 2px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.iconlinkhub {
    width: 18px;
    float: left;
}

.hublinktext {
    display: block;
    margin-left: 26px;
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    font-weight: bold;
}

.active.newperpel {
    color: #8280FF !important;
}

.hubPannelgrid.setperpelborder {
    border-color: #8280FF;
}

.jurneytab li.active.newperpel::before {
    background: #8280FF;
}

.listofUser.huboverdue .hubPannelgrid {
    border-color: #e00;
    background-color: rgba(224, 26, 79, 0.12);
    width: 100%;
}

.bgdispopup {
    background: #e8f7f4;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 120px;
    border: 2px solid #b3e5db;
}


.AnnoucePostCommeit {
    position: relative;
    top: -2px;
}

.annoucementIconschet {
    top: -1px;
}


.anncoumentCommit {
    top: -2px;
}

.engageleftVideo {
    margin-left: 5px;
    margin-top: 2px;
}

.sendCommit {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 35px;
    bottom: 7px;
    background: transparent;
    width: 64px;
    border-radius: 20px;
    height: 34px;
    resize: none;
}

.sendiconcommit {
    cursor: pointer;
}

.gifm.sendiconcommit {
    cursor: pointer;
    margin-right: 8px;
}

.setfileUpload input {
    position: absolute;
    top: 8px;
    opacity: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    left: 12px;
    pointer-events: none;
}

label.setfileUpload {
    cursor: pointer;
    margin: 0;
}


.imagepreview {
    float: left;
    width: 130px;
    height: 130px;
    object-fit: contain;
    margin-bottom: 20px;
    border-radius: 4px;
    margin-left: 47px;
    position: relative;
}

.redCross {
    background: #e00;
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    right: -7px;
    text-align: center;
    border-radius: 50px;
}

.redCross img {
    width: 15px;
}

.loadHubcomment {
    font-size: 14px;
    font-weight: bold;
    float: left;
}

.sendCommit.editEgage {
    width: 60px;
}



.sendCommit.true .gificonSet {
    position: absolute;
    left: 43px;
}

.sendCommit.true {
    width: 100%;
    justify-content: flex-end;
    right: 18px;
    top: auto;
    bottom: 1px;
}

.sendCommit.false {
    align-items: center;
    justify-content: center;
    width: 64px !important;
}

.AnnoucementHeading h2 {
    font-size: 14px;
    font-weight: 700;
    color: #0c2649;
    margin-bottom: 0;
    display: inline-block;
    cursor: pointer;
}

.setrightBoxte,
.showMoretext {
    display: inline-block;
}

.datePannel {
    display: block;
}

.hubTextarea.edit.engageHub textarea {
    height: auto !important;
    overflow: auto;
}

.hubcommenttext {
    margin-left: 13px;
}

/*----lauchpadcard----*/
.lauchpadCardbox {
    border: 1px solid #E6E8F0;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    position: relative;
}

// .setNewpopforfilter {
//     position: absolute !important;
//     background: #fff;
// }


.likebuttontopic.marginHub {
    margin-top: 2px;
}

.launchcardMediaLeft {
    float: left;
    background-color: #46BFA5;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    padding: 5px;
}

.launchcardMediaLeft img {
    width: 100%;
}

.dotesLauchpad {
    float: right;
    cursor: pointer;
    position: relative;
}

.lauchpadDropList {
    position: absolute;
    right: 0px;
    top: 30px;
    width: 150px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 10px;
    z-index: 10;
}

.lauchpadcardright {
    margin-left: 40px;
    font-size: 14px;
}

.lauchpadcardright h2 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
}

.lauchpadDropList ul {
    margin: 0px;
    padding: 0px;
}

.lauchpadDropList ul>li {
    display: block;
    margin-bottom: 10px;
}

.iconPic {
    float: left;
    margin-right: 7px;
}

.lauchpadcardright p {
    color: #596A80;
    font-size: 12px;
    margin: 0px;
    max-width: 202px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.searchResourcefolder h2 {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    position: relative;
    left: -7px;
}

.searAraeresource {
    display: inline-block;
    position: relative;
    width: calc(100% - 297px);
}


.searchResourcefolder {
    width: 63%;
    display: flex;
    align-items: center;
}

.add-new {
    display: inline-block;
    margin-top: 3px;
}


.assignSelect {
    float: left;
    width: 100%;
    margin-left: 0;
    position: relative;
    border: 1px solid #dcdcdc;
    padding: 0px 15px 10px 15px;
    border-radius: 5px;
    margin-top: 65px;
}

.droppable {
    width: 100%;
}


.assignselectHead {
    border: 1px solid #d7d7d7;
    padding: 9px;
    border-radius: 5px;
}

.assignSelectbody ul {
    margin: 0px;
    padding: 0px;
}

.assignSelectbody ul li {
    display: flex;
    font-weight: 600;
    padding: 5px 0px;
    color: #596A80;
    font-size: 14px;
    position: relative;
    margin-top: 5px;
    border-radius: 3px;
    padding-right: 27px;
}



.setterdorpdown {
    position: relative;
}


.assignSelectbody {
    background: #fff;
    width: calc(100% + 13px);
    overflow: auto;
    max-height: 350px;
    margin: 0px 13px 0px 0px;
    display: block;
}

.assignGroptest {
    font-weight: bold;
    font-size: 13px;
    padding: 10px 10px;
    border-bottom: 1px solid #dcdcdc;
    margin: 0px -16px;
}

.assignselectHead span {
    float: right;
    font-size: 16px;
    position: relative;
    top: -2px;
}


.assginGroupPopup {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 500px;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 1000;
    text-align: center;
    border-radius: 5px;
    padding: 10px 20px 15px;
}

.assginGroupPopupheading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.closepoupassign {
    position: absolute;
    right: 17px;
}

.assginGroupPopupheading h2 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
}

.formnewSelectprogram {
    text-align: left;
}


.buttonsumbitassing {
    border: 1px solid #46bfa5;
    color: #46bfa5;
    border-radius: 50px;
    padding: 7px 20px;
    background: #fff;
}

.buttonsumbitassing:hover {
    background-color: #46bfa5;
    color: #fff;
}

.buttonareaassign {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
}

.assigngrouplabel {
    position: relative;
    width: 100%;
    margin-bottom: 9px;
    min-height: 60px;
}


/*----lauchpadcard----*/



.launlistEclips {
    max-width: 202px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}


.closePannelengagebtn {
    position: absolute;
    right: 10px;
    display: flex;
    width: 16px;
    height: 16px;
    top: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    opacity: 1;
    cursor: pointer;
}

.closePannelengagebtn:hover {
    opacity: 0.5;
}

.selectValidation {
    bottom: -28px !important;
}

.EclipsEngage .launchBoxRight p {
    max-width: 235px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}


.inputSearchresource {
    position: absolute;
    top: 9px;
    left: 10px;
}

.lauchpadCardbox .dropdown-item {
    padding: 7px 15px !important;
}








/*-----resourcechange---*/

.searAraeresource input {
    border: 1px solid #EEEEF0;
    padding: 12px 10px 9px 30px;
    margin: 0px;
    border-radius: 5px;
    width: 100%;
    outline: none;
    font-size: 14px;
}


.justiccontentLine .searAraeresource input {
    width: 500px;
}


.searAraeresource span {
    position: absolute;
    top: 11px;
    left: 6px;
}


.assignDrop {
    position: absolute;
    top: 0;
    right: 0;
}

.AssignOption {
    position: absolute;
    z-index: 1000;
    top: 44px;
    width: 194px;
    background: #fff;
    max-height: 200px;
    overflow: auto;
    padding: 5px 10px 2px;
    display: flex;
    flex-direction: column;
    border: 1px solid #EEEEF0;
    border-radius: 5px;
    right: 0;
    box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2) !important;
}

.AssignOption p {
    margin: 0;
}

.AssignOption .chk {
    margin: 3px 0px !important;
}

.recourcCenter {
    position: relative;
    top: -30px;
    left: -4px;
    float: left;
    width: calc(100% - 220px);
    display: block;
}

.justiccontentLine {
    width: calc(100% - 140px) !important;
    align-items: center !important;
    justify-content: space-between !important;

}

.dropIcon {
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 18px;
}

.assignGroupDropdown {
    cursor: pointer;
    position: relative;
    border: 1px solid #cbcbcb;
    padding: 10px 0px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    width: 194px;
    align-items: center;
    justify-content: center;
}

.filterpannelGrop {
    display: flex;
    overflow: scroll;
    white-space: nowrap;
}


@media screen and (max-width: 767px) {
    .engagelistflex.false {
        display: none !important;
    }

    .likebuttontopic {
        margin-right: 0px !important;
    }

    .commeintEngagePannel {
        top: 1px !important;
    }

    .engagelistflex.forEngage.engageiconsFist.listiconCenterEngage {
        display: none !important;
    }

    .flexboxicons {
        display: flex;
    }

    .engagelistflex.showEngagelist {
        display: block;
    }

    .emogisResponsive {
        width: 35px;
        cursor: pointer;
        display: block;
        margin: 4px 4px 0px 0px;
        float: left;
    }

    .engagelistflex.showresponsive {
        display: none !important;
    }


    .greenannoucebtn.responsivepostbtn {
        width: auto;
    }

    .tabDisc {
        position: relative;
        top: 20px;
    }


    .backsetAnnoucement {
        left: 46px;
    }

    .tabtdxt {
        margin-top: 3px !important;
        margin-left: 7px;
        margin-bottom: 3px;
    }

    .topicdiscussionlike--show {
        margin-top: 3px !important;
        margin-left: 10px;
        margin-bottom: 3px;
    }

    .annouceListPannel {
        margin-top: 30px;
    }

    .engagelistflex {
        display: flex !important;
        margin-left: 9px !important;
        flex-wrap: nowrap !important;
        max-width: 206px !important;
        overflow: auto;
        position: absolute;
        top: 30px;
        background: #e9e9e9;
        z-index: 10;
        border-radius: 5px;
    }

    .emojisrapper {
        flex-wrap: wrap;
        justify-content: flex-start !important;
    }

    .fullScreen {
        display: none;
    }

    .smallScreen {
        display: block;
        float: left;
        margin-top: 10px;
    }

    .dashboardVideo .Engagereact-player {
        margin-top: 10px !important;
        height: 100% !important;
        width: 100% !important;
    }

    .viewSquare {
        top: 6px;
    }

    .annouceleftPannel {
        width: 100%;
    }

    // .dashboardVideo {
    //     height: 145px !important;
    // }

    .smallScreen .likebtnannouce {
        padding: 0px 6px;
    }


    .newLuanchpadmain {
        flex-direction: column;
    }

    .newlaunchpadflexstart {
        width: 100%;
    }

    .newLaunchpadleft {
        width: 100%;
        padding-left: 0px;
    }

    .annoucementpannel {
        align-items: flex-start;
    }

    .tablistnewLaunchpad ul {
        display: flex;
        align-items: center;
        padding: 0px 0px 0px 20px;
        justify-content: space-around;
    }

    .tablbtnlaunch {
        padding: 5px 12px;
        font-size: 12px;
    }
}

@media screen and (max-width: 565px) {
    .annouceDetailPoup {
        width: 320px;
    }

    .buttonannoucement {
        margin-left: 10px;
    }

    .tabDisc>ul>li {
        padding: 2px 10px 2px 0px;
    }

    .footerEmojis>ul {
        align-items: flex-start;
        flex-direction: column;
    }

    .footerEmojis>ul {
        flex-direction: row !important;
    }
}
