.giphy-container {
    position: fixed;
    z-index: 1000;
    background: #fff;
    width: 504px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 15px;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: scroll;
    height: 500px;
    border: 1px solid#ddd;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
    padding: 8px 15px;
  }

  .gif {
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    border-radius: 0px;
    outline: none;
    margin: 0px;
    padding: 0px;
    border: 0px;
    font: inherit;
    position: relative;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .gif__image-container {
    margin: 5px;
    cursor: pointer;
    color: rgb(255, 255, 255);
    position: relative;
    vertical-align: baseline;
    position: relative;
  }

  .gif__image {
    color: rgb(255, 255, 255);
    border-radius: 8px;
    width: 105px;
  }

  .giphy-container input {
    margin-block: 10px !important;
  }

  .gifcontent {
    position: sticky;
    right: 0px;
    width: 208px;
    top: 450px;
    left: 280px;
    z-index: 1;
  }

  .gifcontent span {
    font-size: 21px;
    font-weight: bold;
    display: block;
    line-height: 17px;
    text-transform: uppercase;
  }